import { useCallback } from 'react'
import useToggle from '~/hooks/useToggle'
import Button from './UIKit/Button'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  confirmationLabel?: string
  confirmationAriaLabel?: string
}

function ConfirmationButton({
  confirmationLabel = 'Are you sure?',
  confirmationAriaLabel,
  children,
  ...rest
}: Props) {
  const { value: confirm, on: confirmationOn, off: confirmationOff } = useToggle()

  const onClick = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    confirmationOn()
  }, [confirmationOn])

  return (
    confirm ?
      <Button
        variant="danger"
        {...rest}
        onMouseLeave={confirmationOff}
        aria-label={confirmationAriaLabel}
      >
        {confirmationLabel}
      </Button> :
      <Button
        {...rest}
        type="button"
        onClick={onClick}
      >
        {children}
      </Button>
  )
}

export default ConfirmationButton
